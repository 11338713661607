interface AlertProps {
	message?: string
	type?: 'error'
}

export const Alert = ({
	message = `There's been an error, please try again later.`,
	type = 'error',
}: AlertProps): JSX.Element => {
	const colour = {
		error: 'text-red-500 bg-[#ffe5e5]',
	}

	return (
		<div>
			<div
				className={`${colour[type]} mb-4 inline-flex items-center rounded p-4 text-sm`}
				role="alert"
			>
				<svg
					aria-hidden="true"
					className="mr-3 inline h-4 w-4 flex-shrink-0"
					fill="currentColor"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
				</svg>
				<span className="sr-only">Info</span>
				<div>{message}</div>
			</div>
		</div>
	)
}
