import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'
import type {
	NavItemLinkContentItem,
	AuthNavItemLinkContentItem,
} from '../models'

const processUrl = (url: string, locale: string): string | undefined => {
	if (!url) {
		return undefined
	}
	return addLocalePrefix(url, locale)
}
export function mapLinksWithLocale(
	arr: Tersed<NavItemLinkContentItem | AuthNavItemLinkContentItem>[],
	locale: string
): Tersed<NavItemLinkContentItem | AuthNavItemLinkContentItem>[] {
	return arr.map((link) => ({
		...link,
		elements: {
			...link.elements,
			url: processUrl(link.elements.url || '', locale) ?? '',
			sublinks: mapLinksWithLocale(link.elements.sublinks, locale),
		},
	}))
}
