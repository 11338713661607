import {useEffect, useRef, useState} from 'react'
import Link from 'next/link'
import Svg from 'react-inlinesvg'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import type Slider from 'react-slick'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {CarouselCtaContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/carousel-cta-block'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {FullWidthCarousel} from '../../components/carousel/full-width-carousel'

export type ParasiteSelectorCtaCarouselContentItem = IContentItem<{
	title: Elements.TextElement
	numberOfItemsPerSlide: Elements.NumberElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	carouselCtas: Elements.LinkedItemsElement<CarouselCtaContentItem>
}>

export const ParasiteSelectorCtaCarouselBlock: Block<
	ParasiteSelectorCtaCarouselContentItem
> = ({block}) => {
	const router = useRouter()
	const [activeSlideIndex, setActiveSlideIndex] = useState(0)
	const sliderRef = useRef<Slider>(null)
	const componentSpacing = block.elements.snippetSpacingSpacing[0]?.codename
		? spacing[
				block.elements.snippetSpacingSpacing[0]
					.codename as keyof typeof spacing
			]
		: spacing.none

	useEffect(() => {
		const newSlideIndex = block.elements.carouselCtas.findIndex(
			(item) => item.elements.link === router.asPath
		)
		setActiveSlideIndex(newSlideIndex)
		sliderRef.current?.slickGoTo(newSlideIndex)
	}, [block.elements.carouselCtas, router.asPath])

	return (
		<div className={`container-wide pb-16 ${componentSpacing}`}>
			{block.elements.title ? (
				<h3 className="mb-8 text-center text-xl font-normal text-black">
					{block.elements.title}
				</h3>
			) : null}
			{block.elements.carouselCtas.length > 0 && (
				<FullWidthCarousel
					hasHrBelow
					ref={sliderRef}
					stylesClass="verticalyCenteredArrowsBlack"
				>
					{block.elements.carouselCtas.map((carouselCta, index) => (
						<div key={carouselCta.system.id}>
							<Link
								href={addLocalePrefix(
									carouselCta.elements.link,
									router.locale ?? ''
								)}
								passHref
							>
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- TODO: remove when next/link updates */}
								<a className="group flex flex-col items-center">
									<Svg
										className={clsx(
											activeSlideIndex === index
												? 'text-pet-green'
												: 'text-elanco-beige group-hover:text-pet-green',
											`mb-2 h-14 w-14`
										)}
										preProcessor={(code) =>
											code
												.replace(
													/fill=".*?"/g,
													'fill="currentColor"'
												)
												.replace(
													/stroke=".*?"/g,
													'stroke="currentColor"'
												)
										}
										src={
											carouselCta.elements.icon[0]?.url ??
											''
										}
									/>
									<p className="truncate text-center text-sm font-bold text-black">
										{carouselCta.elements.title}
									</p>
								</a>
							</Link>
						</div>
					))}
				</FullWidthCarousel>
			)}
		</div>
	)
}
