import type {PropsWithChildren} from 'react'
import {createContext, useState} from 'react'
import type {IGlobalState, TGlobalState} from '@/models/GlobalState'

type GlobalClientStateProps = PropsWithChildren<{
	initialGlobalState?: IGlobalState
}>

export const GlobalContext = createContext<TGlobalState | null>(null)

export const GlobalClientState = ({
	children,
	initialGlobalState,
}: GlobalClientStateProps): JSX.Element => {
	const [globalState, setGlobalState] = useState<IGlobalState | null>(
		initialGlobalState ?? null
	)

	return (
		<GlobalContext.Provider
			value={[globalState, setGlobalState] as TGlobalState}
		>
			{children}
		</GlobalContext.Provider>
	)
}
