import {bcp47List} from '@/utils/constants'

const matchAbsoluteUrlPath = (path: string): boolean => {
	// eslint-disable-next-line prefer-regex-literals -- disabled
	const pattern = new RegExp('^(?:[a-z+]+:)?//', 'i')
	// returns 'true' if the URL is external
	return pattern.test(path)
}
export const addLocalePrefix = (url: string, locale: string): string => {
	const trimmedUrl = url.trim()
	const isExternal = matchAbsoluteUrlPath(trimmedUrl)
	const keys = Object.keys(bcp47List)

	if (trimmedUrl === '') return trimmedUrl
	if (
		['mailto:', 'tel:', 'sms:', '#'].some((prefix) =>
			trimmedUrl.startsWith(prefix)
		)
	)
		return trimmedUrl

	// If the URL starts with locale or is an external URL
	if (isExternal || trimmedUrl.startsWith(`/${locale}`)) {
		return trimmedUrl
	}
	// If the URL does not start with forward slash '/' and with the desired '/locale' but instead with different locale
	if (
		!trimmedUrl.startsWith('/') &&
		!trimmedUrl.startsWith(`/${locale}`) &&
		keys.some((key) => trimmedUrl.startsWith(key))
	) {
		return `/${trimmedUrl}`
	}
	// If the URL starts with a locale without the forward slash '/'
	if (!trimmedUrl.startsWith('/') && trimmedUrl.startsWith(locale)) {
		return `/${trimmedUrl}`
	}
	// If the URL does not start with both forward slash '/' and '/locale'
	if (!trimmedUrl.startsWith('/') && !trimmedUrl.startsWith(`/${locale}`)) {
		return `/${locale}/${trimmedUrl}`
	}
	// If the URL starts with forward slash '/' and does not start with desired '/locale' but instead starts with different locale
	if (
		trimmedUrl.startsWith('/') &&
		!trimmedUrl.startsWith(`/${locale}`) &&
		keys.some(
			(key) =>
				trimmedUrl.startsWith(`/${key}/`) || trimmedUrl === `/${key}`
		)
	) {
		return trimmedUrl
	}
	return `/${locale}${trimmedUrl}`
}
