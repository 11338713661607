import type {FC, PropsWithChildren} from 'react'

interface ModalProps {
	size: 'sm' | 'md' | 'lg' | 'xl'
	title?: string
	titleCenter?: boolean
	onClose: () => void
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
	size,
	title = undefined,
	titleCenter = false,
	onClose,
	children,
}) => {
	const modalSizes = {
		sm: 'max-w-sm',
		md: 'max-w-md',
		lg: 'max-w-xl',
		xl: 'max-w-3xl',
	}

	return (
		<div
			aria-modal
			className="fixed bottom-0 left-0 right-0 top-0 z-[1000] flex items-center justify-center bg-black/50"
		>
			<div
				className={`${modalSizes[size]} m-6 h-fit max-h-[90%] overflow-y-auto rounded border-light-grey bg-white shadow-lg`}
			>
				<div className="mr-4 mt-4 flex justify-end">
					<button onClick={onClose} type="button">
						<span aria-label="Close">
							<svg
								height="19.784"
								viewBox="0 0 19.789 19.784"
								width="19.789"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M23.524,21.181l7.068-7.068a1.656,1.656,0,1,0-2.342-2.342l-7.068,7.068-7.068-7.068a1.656,1.656,0,1,0-2.342,2.342l7.068,7.068-7.068,7.068a1.656,1.656,0,0,0,2.342,2.342l7.068-7.068,7.068,7.068a1.656,1.656,0,0,0,2.342-2.342Z"
									data-name="Icon ionic-ios-close"
									fill="#021546"
									transform="translate(-11.285 -11.289)"
								/>
							</svg>
						</span>
					</button>
				</div>
				<div className="p-8 pt-2">
					{title ? (
						<h2
							className={`text-pet-primary ${
								titleCenter ? 'text-center' : 'text-left'
							} mb-4 text-2xl font-bold`}
						>
							{title}
						</h2>
					) : null}
					{children}
				</div>
			</div>
		</div>
	)
}
