import {Button} from '@elanco/component-library-v2'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'
import type {
	GlobalConfigContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'

interface RefillButtonProps {
	id?: string
	className?: string
	globalConfig: Tersed<GlobalConfigContentItem>
}

export const RefillButton = ({
	id,
	globalConfig,
	className,
}: RefillButtonProps): JSX.Element => {
	const {locale = ''} = useRouter()
	const buttonBlock = globalConfig.elements.medicationRefillButtons.find(
		(btn) => btn.elements.id === id
	)?.elements.ctaButton[0]

	// eslint-disable-next-line react/jsx-no-useless-fragment -- TODO: update when react types are fixed
	if (!buttonBlock) return <></>

	return (
		<Link href={addLocalePrefix(buttonBlock.elements.url, locale)} passHref>
			<Button
				as="a"
				className={className || 'w-full'}
				target={
					buttonBlock.elements.openLinkInNewTab[0]?.codename === 'yes'
						? '_blank'
						: '_self'
				}
				variant={
					buttonBlock.elements.variant[0]?.codename ||
					'pet_primary_green'
				}
			>
				{buttonBlock.elements.text}
			</Button>
		</Link>
	)
}
