import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import {getContainerClass} from '../helpers'

export type RichTextContentItem = IContentItem<{
	content: Elements.RichTextElement
	textAlignment: Elements.MultipleChoiceElement
	tableAlignment: Elements.MultipleChoiceElement
	tabTitle: Elements.TextElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	snippetContainerResize: Elements.MultipleChoiceElement
	showTextDivider: Elements.MultipleChoiceElement
	tableTextAlignment: Elements.MultipleChoiceElement
}>

export const RichTextBlock: ExtendedBlock<
	RichTextContentItem,
	{
		className?: string
		richTextRendererClassName?: string
	}
> = ({
	block,
	BlockMapper,
	page,
	className,
	richTextRendererClassName,
	...context
}) => {
	const componentSpacingChoice: keyof typeof spacing =
		(block.elements.snippetSpacingSpacing[0]?.codename as
			| keyof typeof spacing
			| undefined) ?? 'none'
	const componentSpacing = spacing[componentSpacingChoice]

	const containerSizingChoice: keyof typeof spacing | null =
		(block.elements.snippetContainerResize[0]?.codename as
			| keyof typeof spacing
			| undefined) ?? null
	const containerSize = getContainerClass(
		containerSizingChoice || 'none',
		'w-full'
	)

	const textAlignment = ((): string | null => {
		switch (block.elements.textAlignment[0]?.codename) {
			case 'left': {
				return 'text-left'
			}
			case 'center': {
				return 'text-center'
			}
			case 'right': {
				return 'text-right'
			}
			case 'justify': {
				return 'text-justify'
			}
			default: {
				return 'text-center'
			}
		}
	})()

	return (
		<>
			<div
				className={clsx(
					'flex flex-col',
					componentSpacing,
					containerSize,
					textAlignment,
					className
				)}
				data-kontent-element-codename="content"
				style={{overflowWrap: 'anywhere'}}
			>
				<RichTextRenderer
					BlockMapper={BlockMapper}
					className={richTextRendererClassName}
					element={block.elements.content}
					page={page}
					{...context}
				/>
			</div>
			{block.elements.showTextDivider[0]?.codename === 'yes' ? (
				<hr
					className="mt-6 border border-gray-300"
					data-kontent-element-codename="show_text_divider"
				/>
			) : null}
		</>
	)
}
