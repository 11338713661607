import {Icon as CIcon, StatsIcons} from '@elanco/component-library-v2'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {IconWithTextAndCtaContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon-with-text'
import {customBrandChoices, customBrands} from '../helpers'
import {ReusableCTAButtonComponent} from '../cta-button'
import {spacing} from '../../styles'

export type SmallBenefitsBlockContentItem = IContentItem<{
	iconWithTextAndCta: Elements.LinkedItemsElement<IconWithTextAndCtaContentItem>
	borderOption: Elements.MultipleChoiceElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	backgroundOption: Elements.MultipleChoiceElement
}>

export const SmallBenefitsBlockBlock: Block<SmallBenefitsBlockContentItem> = ({
	block,
	...context
}) => {
	const spacingChoice =
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		]
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, spacingChoice can be undefined
	const componentSpacing = !spacingChoice ? spacing.default : spacingChoice

	return (
		<div className={`container-wide ${componentSpacing}`}>
			<StatsIcons className="px-2" col={2} grayBG={false} hasNoFlexWrap>
				{block.elements.iconWithTextAndCta.map(
					({
						elements: {
							icon,
							brand,
							size,
							title,
							boldTitle,
							content,
							buttons,
						},
						system: {id: kItemId},
					}) => (
						<div data-kontent-item-id={kItemId} key={kItemId}>
							<CIcon
								brand={
									brand[0]?.codename &&
									customBrandChoices.includes(
										brand[0].codename
									)
										? 'none'
										: brand[0]?.codename || 'none'
								}
								iconCustomClasses={
									brand[0]?.codename &&
									customBrandChoices.includes(
										brand[0]?.codename
									)
										? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- will be non-null
											customBrands[brand[0].codename]!
												.border
										: undefined
								}
								noBackground={
									block.elements.backgroundOption[0]
										?.codename === 'yes'
								}
								noBorder={
									!(
										block.elements.borderOption[0]
											?.codename === 'yes'
									)
								}
								size={size[0]?.codename ?? 'lg'}
								title={
									<span
										className={
											boldTitle[0]?.codename === 'bold'
												? 'font-bold'
												: undefined
										}
										data-kontent-element-codename="title"
									>
										{title}
									</span>
								}
							>
								<div className="relative h-full w-full">
									<Image
										alt={icon[0]?.description ?? ''}
										data-kontent-element-codename="icon"
										fill
										loader={loadImageFromKontentAI}
										src={
											icon[0]?.url ??
											'https://assets-eu-01.kc-usercontent.com/43db687c-fb47-015f-c990-00802940e2bc/f03522f9-0615-4ca8-a83c-3eb311fd2159/placeholder-256x176.png'
										}
									/>
								</div>
							</CIcon>
							<div
								dangerouslySetInnerHTML={{
									__html: content.value,
								}}
								data-kontent-element-codename="content"
							/>
							{buttons.map((button) => (
								<ReusableCTAButtonComponent
									block={button}
									className="mt-6"
									key={button.system.id}
									{...context}
								/>
							))}
						</div>
					)
				)}
			</StatsIcons>
		</div>
	)
}
