import {DynamicCardsLister} from '@elanco/component-library-v2'
import type {ReactNode} from 'react'
import {useState, useEffect} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import {getContainerClass} from '../helpers'

interface ListerProps {
	columns?: number
	showAsSlider?: boolean
	isSliderOnMobile?: boolean
	snippetContainerResize?: string
	children?: ReactNode
	ofLabel?: string
	autoplay?: boolean
	/** Speed in ms that the lister should auto-play */
	autoplaySpeed?: number
}

const ListerDefaults = {
	columns: 3,
	showAsSlider: false,
	isSliderOnMobile: false,
	children: [] as ReactNode,
}

export const Lister = ({
	columns,
	showAsSlider,
	isSliderOnMobile,
	snippetContainerResize,
	children,
	ofLabel,
	autoplay = false,
	autoplaySpeed = 2000,
}: ListerProps = ListerDefaults): JSX.Element => {
	const [windowWidth, setWindowWidth] = useState(0)
	const containerSize = getContainerClass(snippetContainerResize ?? '')
	const size: {isMobile: boolean | undefined} = useWindowSize()

	useEffect(() => {
		setWindowWidth(window.innerWidth)
	}, [])

	return (
		<DynamicCardsLister
			autoplay={autoplay}
			autoplaySpeed={autoplaySpeed}
			className={
				windowWidth < 768 || size.isMobile ? 'w-full' : containerSize
			}
			columns={columns}
			hideSliderUI={autoplay}
			isEqualHeightContent
			isSliderOnMobile={isSliderOnMobile}
			ofLabel={ofLabel}
			showAsSlider={showAsSlider}
		>
			{children}
		</DynamicCardsLister>
	)
}

export type CardListerContentItem = IContentItem<{
	items: Elements.LinkedItemsElement
	columns: Elements.MultipleChoiceElement
	slider: Elements.MultipleChoiceElement
	mobileSlider: Elements.MultipleChoiceElement
	snippetContainerResize: Elements.MultipleChoiceElement
	ofLabel: Elements.TextElement
}>

export const CardListerBlock: Block<CardListerContentItem> = ({
	block,
	BlockMapper,
	...context
}) => {
	return (
		<Lister
			columns={Number(block.elements.columns[0]?.name ?? 3)}
			isSliderOnMobile={
				block.elements.mobileSlider[0]?.codename === 'yes'
			}
			ofLabel={block.elements.ofLabel}
			showAsSlider={block.elements.slider[0]?.codename === 'yes'}
			snippetContainerResize={
				block.elements.snippetContainerResize[0]?.codename
			}
		>
			{block.elements.items.map((item) => (
				<BlockMapper
					blocks={[item]}
					key={item.system.id}
					{...context}
				/>
			))}
		</Lister>
	)
}
