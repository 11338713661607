import NextLink from 'next/link'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'
import type {BlockWithClassName} from '@/_new-code/services/kontent-ai/types'

export type LinkContentItem = IContentItem<{
	text: Elements.TextElement
	url: Elements.TextElement
	icon: Elements.MultipleChoiceElement
	openInNewTab: Elements.MultipleChoiceElement
}>

export const LinkBlock: BlockWithClassName<LinkContentItem> = ({
	block: {
		elements: {openInNewTab, text, url},
	},
	className,
}) => {
	const {locale = ''} = useRouter()
	return (
		<NextLink href={addLocalePrefix(url, locale)} passHref>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- required for next/link */}
			<a
				className={className}
				data-kontent-element-codename="text"
				target={
					openInNewTab[0]?.codename === 'yes' ? '_blank' : '_self'
				}
			>
				{text}
			</a>
		</NextLink>
	)
}
