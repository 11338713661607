import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useRouter} from 'next/router'
import {Button} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Reminder} from '@/fetchers/fetchReminders'
import {fetchPetReminders} from '@/fetchers/fetchReminders'
import LoadingState from '@/components/Form/LoadingState'
import {Modal} from '@/_new-code/components/modal/modal'
import type {Pet} from '@/models/consumerEngagement/models'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import type {DeletePet} from '@/_new-code/products/your-pet-and-you/mutations/delete-pet-profile'
import {deletePetProfile} from '@/_new-code/products/your-pet-and-you/mutations/delete-pet-profile'
import {deleteReminder} from '@/_new-code/products/your-pet-and-you/mutations/mutate-reminders'

export type PetDeleteModalContentItem = IContentItem<{
	petDeleteModalHeading: Elements.TextElement
	petDeleteModalDescription: Elements.RichTextElement
	petDeleteButtonText: Elements.TextElement
	petDeleteGoBackToEditLinkText: Elements.TextElement
	redirectUrl: Elements.TextElement
}>

export const PetDeleteModalBlock: ExtendedBlock<
	PetDeleteModalContentItem,
	{
		showModal: boolean
		petData: Pet
		hideModal: () => void
	}
> = ({
	block: {
		elements: {
			petDeleteButtonText,
			petDeleteGoBackToEditLinkText,
			petDeleteModalDescription,
			petDeleteModalHeading,
			redirectUrl,
		},
	},
	showModal,
	petData,
	hideModal,
	...context
}) => {
	const router = useRouter()
	const {locale: pageLocale = ''} = router

	const queryClient = useQueryClient()
	const deletePetMutation = useMutation({
		mutationFn: (petId: DeletePet) => deletePetProfile(petId, pageLocale),
		onSuccess: () => queryClient.invalidateQueries({queryKey: ['pets']}),
	})
	const deleteReminderMutation = useMutation({
		mutationFn: (reminderId: string) =>
			deleteReminder(reminderId, pageLocale),
		onSuccess: () =>
			queryClient.invalidateQueries({queryKey: ['reminders']}),
	})

	const {data: reminders, isLoading} = useQuery<Reminder[], Error>({
		queryKey: ['reminders'],
		queryFn: () => fetchPetReminders(pageLocale),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
	})

	const handleDeletePetProfile = async (petId: string): Promise<void> => {
		if (reminders) {
			const petReminders = reminders.filter(
				(reminder) => reminder.pet?.id === petId
			)
			await Promise.all(
				petReminders.map((rem) =>
					deleteReminderMutation.mutateAsync(rem.id)
				)
			)
		}

		await deletePetMutation.mutateAsync({
			petId,
		})

		hideModal()
		void router.push({pathname: `/${pageLocale}${redirectUrl}`}, undefined)
	}

	const isLoadingData =
		isLoading ||
		deleteReminderMutation.isLoading ||
		deletePetMutation.isLoading

	return (
		<div>
			{showModal ? (
				<Modal
					onClose={hideModal}
					size="md"
					title={petDeleteModalHeading}
					titleCenter
				>
					<div className="relative">
						<LoadingState isLoading={isLoadingData} theme="green" />
						<div className="flex flex-col items-center justify-center">
							<RichTextRenderer
								element={{
									...petDeleteModalDescription,
									value: petDeleteModalDescription.value.replaceAll(
										/{{petName}}/gi,
										petData.name
									),
								}}
								{...context}
							/>
							<div className="w-full">
								<div className="flex flex-col items-center pt-6">
									<div className="mb-4">
										<Button
											onClick={() =>
												handleDeletePetProfile(
													petData.id
												)
											}
											variant="pet_primary_green"
										>
											{petDeleteButtonText}
										</Button>
									</div>
									<div className="pb-10">
										<button
											className="cursor-pointer text-green-600 underline"
											onClick={hideModal}
											type="button"
										>
											{petDeleteGoBackToEditLinkText}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			) : null}
		</div>
	)
}
