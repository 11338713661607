import {Column as ClColumn} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {getContainerClass, getBrandColor} from '../helpers'
import {spacingPadding} from '../../styles'

export type ColumnContentItem = IContentItem<{
	items: Elements.LinkedItemsElement
	layout: Elements.MultipleChoiceElement
	snippetContainerResize: Elements.MultipleChoiceElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	snippetLayoutBackgroundColorColor: Elements.MultipleChoiceElement
}>

export const ColumnBlock: Block<ColumnContentItem> = ({
	block,
	BlockMapper,
	page,
	...context
}) => {
	const containerSizeOption =
		block.elements.snippetContainerResize[0]?.codename

	const containerSize = containerSizeOption
		? getContainerClass(containerSizeOption, 'container-wide')
		: 'container-wide'

	const componentSpacing = (() => {
		switch (block.elements.snippetSpacingSpacing[0]?.codename) {
			case 'default': {
				return spacingPadding.default
			}
			case 'top': {
				return spacingPadding.top
			}
			case 'bottom': {
				return spacingPadding.bottom
			}
			case 'none': {
				return spacingPadding.none
			}
			default: {
				return spacingPadding.none
			}
		}
	})()

	const pageConfig = page.elements.config[0]
	const brand = pageConfig ? getBrandColor(pageConfig) : null

	const snippetColor =
		block.elements.snippetLayoutBackgroundColorColor[0]?.codename
	const liteBackground =
		snippetColor === 'brand'
			? `!bg-${brand}-light bg-default-light-background`
			: ''

	return (
		<ClColumn
			bgColor={snippetColor}
			className={clsx(
				componentSpacing,
				liteBackground,
				block.system.type
			)}
			containerClassName={containerSize}
		>
			<div className="w-[inherit]">
				{block.elements.items.map((item) => (
					<BlockMapper
						blocks={[item]}
						key={item.system.id}
						page={page}
						{...context}
					/>
				))}
			</div>
		</ClColumn>
	)
}
