import {GetServerSideProps, GetServerSidePropsContext} from 'next'
import {useRouter} from 'next/router'
import {ParsedUrlQuery} from 'querystring'
import {
	PreviewModeBanner,
	PreviewModeData,
} from '@/_new-code/services/kontent-ai/preview-mode/preview-mode-banner'
import handleError from '../services/handleError'

import {KontentService as NewKontentService} from '@/_new-code/services/kontent-ai/service'
import {BlockMapper} from '@/_new-code/services/kontent-ai/mapper'
import {HeaderBlock} from '@/_new-code/products/flexible-web-toolkit/components/header'
import {
	GlobalConfigContentItem,
	AdvancedPageTemplateContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import {PageSEO} from '@/_new-code/products/flexible-web-toolkit/components/page-seo'
import {FooterBlock} from '@/_new-code/products/flexible-web-toolkit/components/footer'
import {StickyBottomNavBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/sticky-bottom-nav'
import {IGlobalState} from '@/models/GlobalState'
import {useAnalytics} from '@/utils/analytics'
import {TaxonomyListerContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/listers/dynamic-article-lister'
import {env} from '@/utils/env/server.mjs'
import {Auth} from '@/_new-code/products/auth/auth-provider'
import {GlobalClientState} from '@/_new-code/services/global-client-state'

export interface PageProps {
	page: Tersed<AdvancedPageTemplateContentItem>
	globalConfig: Tersed<GlobalConfigContentItem>
	auth: Auth
	isInPreviewMode: boolean
	previewData: PreviewModeData
	isOnTaxonomyTermPage: boolean
	reqUrl: string | undefined
	reqHost: string | undefined
}

const DefaultPage = ({
	globalConfig,
	page,
	isInPreviewMode,
	previewData,
	isOnTaxonomyTermPage,
	reqUrl,
	reqHost,
}: PageProps): JSX.Element => {
	const router = useRouter()

	// TODO: better 404 handling
	if (router.locale === 'null') return <p>404 - Not found</p>

	if (typeof window !== 'undefined') {
		useAnalytics(page)
	}

	const Mapper = () => (
		<BlockMapper
			// TODO: just pass page?
			blocks={page.elements.pageBlocks}
			page={page}
			globalConfig={globalConfig}
		/>
	)

	const initialGlobalState: IGlobalState = {
		articlesFilterOptions: {},
		couponsFilterOptions: {},
		productsFilterOptions: {},
		resourcesFilterOptions: {},
		faqSearchQuery: '',
	}

	return (
		<GlobalClientState initialGlobalState={initialGlobalState}>
			<PageSEO
				globalConfig={globalConfig}
				page={page}
				isOnTaxonomyTermPage={isOnTaxonomyTermPage}
				reqUrl={reqUrl}
				reqHost={reqHost}
			/>
			<PreviewModeBanner
				isInPreviewMode={isInPreviewMode}
				previewData={previewData}
			/>
			<HeaderBlock
				page={page}
				globalConfig={globalConfig}
				block={{
					elements: {
						authenticationGateComponentUserRoles: [],
					},
					system: globalConfig.system,
				}}
				BlockMapper={Mapper}
			/>
			<main>
				<BlockMapper
					// TODO: just pass page?
					blocks={page.elements.pageBlocks}
					page={page}
					globalConfig={globalConfig}
				/>
			</main>
			<FooterBlock
				page={page}
				globalConfig={globalConfig}
				block={{
					elements: {
						authenticationGateComponentUserRoles: [],
					},
					system: globalConfig.system,
				}}
				BlockMapper={Mapper}
			/>
			<StickyBottomNavBlock
				page={page}
				globalConfig={globalConfig}
				block={{
					elements: {
						authenticationGateComponentUserRoles: [],
					},
					system:
						globalConfig.elements.stickyBottomNav[0]?.system ??
						globalConfig.system,
				}}
				BlockMapper={Mapper}
			/>
		</GlobalClientState>
	)
}

const parseParams = (
	params: ParsedUrlQuery | undefined
): {
	slug: string | string[] | undefined
	isLoggedIn: boolean
	isValidated: boolean
	isSelfValidated: boolean
} => {
	const slugArray = params?.slug as string[]
	if (!slugArray || slugArray.length < 3) {
		return {
			slug: params?.slug,
			isLoggedIn: false,
			isValidated: false,
			isSelfValidated: false,
		}
	}
	return {
		slug: slugArray.slice(0, -3),
		isLoggedIn: slugArray.slice(-3, -2)[0] === 'true',
		isValidated: slugArray.slice(-2, -1)[0] === 'true',
		isSelfValidated: slugArray.slice(-1)[0] === 'true',
	}
}

export const getServerSideProps: GetServerSideProps<PageProps> = async (
	context: GetServerSidePropsContext
) => {
	try {
		if (context?.locale === 'null' || !context.locale)
			return {notFound: true}

		const {slug, isLoggedIn, isValidated, isSelfValidated} = parseParams(
			context.params
		)

		if (!slug) {
			throw new Error('Slug is nullish')
		}

		const isInPreviewMode = context.preview ?? false
		const previewData = (context.previewData as PreviewModeData) ?? {}
		const parsedSlug = Array.isArray(slug)
			? slug.join('/')
			: slug.toString()
		const kontentService = NewKontentService.getInstance()
		const globalConfig =
			await kontentService.getContentItem<GlobalConfigContentItem>({
				locale: context.locale,
				usePreviewMode: isInPreviewMode,
				codename: env.GLOBAL_CONFIG_NAME,
			})

		if (!globalConfig.success) {
			throw new Error(JSON.stringify(globalConfig.error))
		}

		const isOnTaxonomyTermPage = /taxonomy\/term/.test(context.resolvedUrl)

		let page = await kontentService.getPage({
			locale: context.locale,
			urlSlug: isOnTaxonomyTermPage ? '/404' : parsedSlug, // Pull a 404 page as taxonomy page as a base
			usePreviewMode: isInPreviewMode,
		})

		if (!page.success) {
			const {error} = page
			if (
				typeof error === 'object' &&
				error !== null &&
				'code' in error &&
				typeof (error as {code: number}).code === 'number'
			) {
				const code = (error as {code: number}).code

				if (code === 404) {
					context.res.statusCode = 404
					// redirect to 404 page
					page = await kontentService.getPage({
						locale: context.locale,
						urlSlug: '/404',
						usePreviewMode: isInPreviewMode,
					})

					if (!page.success) {
						throw new Error(JSON.stringify(page.error))
					}
				} else {
					throw new Error(JSON.stringify(page.error))
				}
			} else {
				throw new Error(JSON.stringify(page.error))
			}
		}

		const urlArray = context.req.url ? context.req.url.split('/') : ['']
		const dupeLocale = context.locale === urlArray[1]
		const is404Page = context.resolvedUrl.includes('/404')
		if (is404Page || dupeLocale) {
			context.res.statusCode = 404
		}

		// "Taxonomy" pages are dynamically generated, so we build a lister here to display required cards
		if (isOnTaxonomyTermPage) {
			const tagCodename = parsedSlug
				.replaceAll('taxonomy/term/', '')
				.replaceAll('-', '_')

			const taxonomyLister: Tersed<TaxonomyListerContentItem> = {
				elements: {
					buttonText: '',
					tags: [
						{
							codename: tagCodename,
							name: 'Play',
						},
					],
					columns: 3,
					featuredDescription: '',
					featuredTitle: '',
					initialArticleNumber: 6,
					listerDescription: '',
					listerTitle: '',
					showFeatured: [],
					subsequentArticleNumber: 6,
					titleDescSpacing: [],
					usePagination: [
						{
							codename: 'yes',
							name: 'Yes',
						},
					],
					authenticationGateComponentUserRoles: [],
				},
				system: {
					codename: 'dynamic_article_lister',
					id: 'taxonomy',
					name: 'Taxonomy Lister',
					type: 'dynamic_article_lister',
				},
			}

			page.data.elements.pageBlocks = [taxonomyLister]
			page.data.elements.pageText = ''
			context.res.statusCode = 200
		}

		return {
			props: {
				page: page.data,
				globalConfig: globalConfig.data,
				isInPreviewMode,
				previewData,
				auth: {
					isLoggedIn,
					isValidated,
					isSelfValidated,
				},
				isOnTaxonomyTermPage,
				reqUrl: context.req.url,
				reqHost: context.req.headers.host,
			},
		}
	} catch (error) {
		handleError(JSON.stringify(error), __filename, 'Server error')
		throw error
	}
}

export default DefaultPage
