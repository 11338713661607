import {useEffect, useState} from 'react'
import {iframeResizer} from 'iframe-resizer'
import {useRouter} from 'next/router'
import {ButtonIcons, Button} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {Modal} from '@/_new-code/components/modal/modal'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {type CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'

type SuccessfulRebateModalContentItem = IContentItem<{
	heading: Elements.TextElement
	subHeading: Elements.TextElement
	ctaButtons: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

export type ArrowheadIframeContentItem = IContentItem<{
	iframeUrl: Elements.TextElement
	rebateList: Elements.MultipleChoiceElement
	successfulRebateModalContainer: Elements.LinkedItemsElement<SuccessfulRebateModalContentItem>
}>

export const ArrowheadIframeBlock: Block<ArrowheadIframeContentItem> = ({
	block,
}) => {
	const router = useRouter()
	const code = router.query.code
	const authState = useAuth()
	const {userDetails} = authState

	const modal = block.elements.successfulRebateModalContainer[0]
	const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)

	const handleModal = (event: MessageEvent): void => {
		if (event.data === 'success') {
			setShowSuccessModal(true)
		}
	}

	const showRebatesList = block.elements.rebateList[0]?.codename === 'yes'

	useEffect(() => {
		if (showRebatesList) {
			if (!userDetails.id) {
				return
			}
			const rebateForm: HTMLFormElement | null =
				document.querySelector('#rebate-form')
			rebateForm?.submit()
		}
		iframeResizer({}, '#the_iframe')
	}, [showRebatesList, userDetails.id])

	useEffect(() => {
		if (modal) {
			window.addEventListener('message', handleModal)
			return () => {
				window.removeEventListener('message', handleModal)
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-empty-function -- Intentional
		return () => {}
	}, [modal])

	return (
		<>
			<div>
				{showRebatesList ? (
					<div>
						<form
							action={block.elements.iframeUrl}
							id="rebate-form"
							method="post"
							target="the_iframe"
						>
							<input
								name="UUID"
								type="hidden"
								value={userDetails.id ?? undefined}
							/>
							<input
								name="ProfileFirstName"
								type="hidden"
								value={userDetails.firstName}
							/>
							<input
								name="ProfileLastName"
								type="hidden"
								value={userDetails.lastName}
							/>
							<input
								name="ProfileEmail"
								type="hidden"
								value={userDetails.emailAddress}
							/>
							<input
								name="Brand"
								type="hidden"
								value="TestBrand"
							/>
							<input
								name="OfferCode"
								type="hidden"
								value={code}
							/>
						</form>
					</div>
				) : null}

				<div className="container-wide my-12 md:my-16">
					<iframe
						className="w-full"
						id="the_iframe"
						name="the_iframe"
						src={block.elements.iframeUrl}
						title="Rebates iframe"
					/>
				</div>
			</div>
			{showSuccessModal && modal ? (
				<Modal
					onClose={() => {
						setShowSuccessModal(false)
					}}
					size="lg"
					title={modal.elements.heading}
					titleCenter
				>
					<div className="flex flex-col items-center justify-center">
						<div
							className="mb-4 text-charcoal"
							dangerouslySetInnerHTML={{
								__html: modal.elements.subHeading,
							}}
						/>
						<div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
							{modal.elements.ctaButtons.map((cta) => (
								<Button
									as="a"
									className="w-full md:w-auto"
									href={addLocalePrefix(
										cta.elements.url,
										router.locale ?? ''
									)}
									icon={
										// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: fix component library types
										ButtonIcons[
											// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
											cta.elements.icon[0]
												?.name as keyof typeof ButtonIcons
										]
									}
									key={cta.system.id}
									routerNavigateToPath={(path: string) =>
										router.push(path)
									}
									title={cta.elements.text}
									variant={
										cta.elements.variant[0]?.codename ||
										'pet_primary_green'
									}
								>
									{cta.elements.text}
								</Button>
							))}
						</div>
					</div>
				</Modal>
			) : null}
		</>
	)
}
